import { useState, useEffect } from "react";
import emailjs from 'emailjs-com';
import { Container, Row, Col } from "react-bootstrap";
import contactImg from "../assets/img/contact-img.svg";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';

const phoneFormat = {
  regex: /^\d{10}$/,
  format: (number) => number.replace(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1 $2 $3 $4 $5')
};

export const Contact = () => {
  const { t, i18n } = useTranslation();

  const formInitialDetails = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: ''
  };

  const [formDetails, setFormDetails] = useState(formInitialDetails);
  const [buttonText, setButtonText] = useState(t('Send'));
  const [status, setStatus] = useState({});
  const [errors, setErrors] = useState({});

  useEffect(() => {
    // Update button text when language changes
    setButtonText(t('Send'));
  }, [i18n.language, t]);

  const onFormUpdate = (category, value) => {
    setFormDetails({
      ...formDetails,
      [category]: value
    });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formDetails.firstName) newErrors.firstName = t('First Name is required');
    if (!formDetails.lastName) newErrors.lastName = t('Last Name is required');
    if (!formDetails.email) newErrors.email = t('Email Address is required');
    if (!formDetails.message) newErrors.message = t('Message is required');
    if (!formDetails.phone || !phoneFormat.regex.test(formDetails.phone.replace(/\s+/g, ''))) {
      newErrors.phone = t('Phone number is required and must be 10 digits');
    }
    return newErrors;
  };

  const formatPhoneNumber = (phone) => {
    const cleaned = ('' + phone).replace(/\D/g, '');
    if (!phoneFormat.regex.test(cleaned)) return cleaned;
    return phoneFormat.format(cleaned);
  };

  const handlePhoneChange = (e) => {
    const value = e.target.value;
    const cleaned = value.replace(/\D/g, '');
    setFormDetails({
      ...formDetails,
      phone: cleaned
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    setButtonText(t("Sending..."));

    const formattedPhone = formatPhoneNumber(formDetails.phone);

    const templateParams = {
      to_name: 'Andy DE COCK',
      from_name: `${formDetails.firstName} ${formDetails.lastName}`,
      email: formDetails.email,
      phone: formattedPhone,
      message: formDetails.message
    };

    try {
      const result = await emailjs.send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        templateParams,
        process.env.REACT_APP_EMAILJS_USER_ID
      );

      setStatus({ success: true, message: t('Message sent successfully!') });
      toast.success(t('Message sent successfully!'));
    } catch (error) {
      console.error('Failed to send email:', error);
      setStatus({ success: false, message: t('Something went wrong, please try again later.') });
      toast.error(t('Something went wrong, please try again later.'));
    }

    setButtonText(t("Send")); // Reset button text
    setFormDetails(formInitialDetails);
    setErrors({});
  };

  return (
    <section className="contact" id="connect">
      <Container>
        <Row className="align-items-center">
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) =>
                <img className={isVisible ? "animate__animated animate__zoomIn" : ""} src={contactImg} alt="Contact Us"/>
              }
            </TrackVisibility>
          </Col>
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                <h2>{t('Get In Touch')}</h2>
                <form onSubmit={handleSubmit}>
                  <Row>
                    <Col size={12} sm={6} className="px-1">
                      <input
                        type="text"
                        name="firstName"
                        value={formDetails.firstName}
                        placeholder={t('First Name')}
                        onChange={(e) => onFormUpdate('firstName', e.target.value)}
                        required
                      />
                      {errors.firstName && <div className="error-text">{errors.firstName}</div>}
                    </Col>
                    <Col size={12} sm={6} className="px-1">
                      <input
                        type="text"
                        name="lastName"
                        value={formDetails.lastName}
                        placeholder={t('Last Name')}
                        onChange={(e) => onFormUpdate('lastName', e.target.value)}
                        required
                      />
                      {errors.lastName && <div className="error-text">{errors.lastName}</div>}
                    </Col>
                    <Col size={12} sm={6} className="px-1">
                      <input
                        type="email"
                        name="email"
                        value={formDetails.email}
                        placeholder={t('Email Address')}
                        onChange={(e) => onFormUpdate('email', e.target.value)}
                        required
                      />
                      {errors.email && <div className="error-text">{errors.email}</div>}
                    </Col>
                    <Col size={12} sm={6} className="px-1">
                      <input
                        type="text"
                        name="phone"
                        value={formDetails.phone}
                        placeholder={t('Phone No.')}
                        onChange={handlePhoneChange}
                        maxLength={10}
                      />
                      {errors.phone && <div className="error-text">{errors.phone}</div>}
                    </Col>
                    <Col size={12} className="px-1">
                      <textarea
                        name="message"
                        rows="6"
                        value={formDetails.message}
                        placeholder={t('Message')}
                        onChange={(e) => onFormUpdate('message', e.target.value)}
                        required
                      ></textarea>
                      {errors.message && <div className="error-text">{errors.message}</div>}
                      <button type="submit"><span>{buttonText}</span></button>
                    </Col>
                  </Row>
                </form>
                <ToastContainer position="bottom-right" />
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
