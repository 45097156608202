import React, { useState } from 'react';
import { Col } from 'react-bootstrap';

export const ProjectCard = ({ title, description, imgUrl, url, tech }) => {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleClick = () => {
    if (url) {
      window.open(url, "_blank", "noreferrer");
    } else {
      setIsFlipped(!isFlipped);
    }
  };

  return (
    <Col size={12} sm={6} md={4}>
      <div className={`project-card ${isFlipped ? 'flipped' : ''}`} onClick={handleClick}>
        <div className="project-card-inner">
          <div className="project-card-front">
            <div className="proj-imgbx">
              <img src={imgUrl} alt={title} />
              <div className="proj-txtx">
                <h4>{title}</h4>
                <span>{description}</span>
              </div>
            </div>
            {url && (
              <button className="project-card-button" onClick={() => window.open(url, "_blank", "noreferrer")}>
                View Project
              </button>
            )}
          </div>
          <div className="project-card-back">
            <div className="project-card-tech">
              <h5>Technologies:</h5>
              <p>{tech.split(', ').map((item, index) => (
                <span key={index} className="tech-badge">{item}</span>
              ))}</p>
            </div>
          </div>
        </div>
      </div>
    </Col>
  );
};
