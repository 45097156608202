import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import arrow1 from "../assets/img/arrow1.svg";
import arrow2 from "../assets/img/arrow2.svg";
import colorSharp from "../assets/img/color-sharp.png";
import reactLogo from "../assets/img/react.jpg"; 
import typescriptLogo from "../assets/img/typescript.png"; 
import reactNativeLogo from "../assets/img/react_native.avif"; 
import nodeLogo from "../assets/img/node.png"; 
import htmlLogo from "../assets/img/html5.png";
import rubyLogo from "../assets/img/ruby.jpg";
import stimulusLogo from "../assets/img/stimulus.png";
import javaLogo from "../assets/img/java.webp";
import phpLogo from "../assets/img/php.png";
import pythonLogo from "../assets/img/python.jpg";
import linuxLogo from "../assets/img/linux.png";
import dockerLogo from "../assets/img/docker.png";
import { useTranslation } from 'react-i18next';

export const Skills = () => {
  const { t } = useTranslation();

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <section className="skill" id="skills">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="skill-bx wow zoomIn">
              <h2>{t('Principal Skills')}</h2>
              <p>{t('Skills Description')}</p>
              <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme skill-slider">
                <div className="item">
                  <img src={rubyLogo} alt="Ruby" className="tech-logo" />
                  <h5>{t('Ruby')}</h5>
                </div>
                <div className="item">
                  <img src={typescriptLogo} alt="TypeScript" className="tech-logo" />
                  <h5>{t('TypeScript')}</h5>
                </div>
                <div className="item">
                  <img src={reactLogo} alt="React" className="tech-logo" />
                  <h5>{t('React')}</h5>
                </div>
                <div className="item">
                  <img src={nodeLogo} alt="Node.js" className="tech-logo" />
                  <h5>{t('Node.js')}</h5>
                </div>
                <div className="item">
                  <img src={htmlLogo} alt="HTML" className="tech-logo" />
                  <h5>{t('HTML')}</h5>
                </div>
                <div className="item">
                  <img src={reactNativeLogo} alt="React Native" className="tech-logo" />
                  <h5>{t('React Native')}</h5>
                </div>
                <div className="item">
                  <img src={stimulusLogo} alt="Stimulus" className="tech-logo" />
                  <h5>{t('Stimulus')}</h5>
                </div>
                <div className="item">
                  <img src={javaLogo} alt="Java" className="tech-logo" />
                  <h5>{t('Java')}</h5>
                </div>
                <div className="item">
                  <img src={phpLogo} alt="PHP" className="tech-logo" />
                  <h5>{t('PHP')}</h5>
                </div>
                <div className="item">
                  <img src={pythonLogo} alt="Python" className="tech-logo" />
                  <h5>{t('Python')}</h5>
                </div>
                <div className="item">
                  <img src={linuxLogo} alt="Linux" className="tech-logo" />
                  <h5>{t('Linux')}</h5>
                </div>
                <div className="item">
                  <img src={dockerLogo} alt="Docker" className="tech-logo" />
                  <h5>{t('Docker')}</h5>
                </div>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
      <img className="background-image-left" src={colorSharp} alt="Background" />
      <style jsx>{`
        .tech-logo {
          width: 100px;
          height: 100px;
          object-fit: contain;
          margin: 0 auto;
        }
        .skill-bx {
          text-align: center;
        }
        .item h5 {
          margin-top: 10px;
        }
      `}</style>
    </section>
  );
}
