import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      "Home": "Home",
      "Skills": "Skills",
      "Projects": "Projects",
      "Let’s Connect": "Let’s Connect",
      "Get In Touch": "Get In Touch",
      "First Name": "First Name",
      "Last Name": "Last Name",
      "Email Address": "Email Address",
      "Phone No.": "Phone No.",
      "Message": "Message",
      "Send": "Send",
      "Sending...": "Sending...",
      "Message sent successfully!": "Message sent successfully!",
      "Something went wrong, please try again later.": "Something went wrong, please try again later.",
      "With link": "With link",
      "Without link": "Without link",
      "Overview": "Overview",
      "Project 1": "Spotify Clone",
      "Project 2": "online health record",
      "Description 1": "Description 1",
      "Description 2": "Description 2",
      "Description 3": "Description 3",
      "Description 4": "Description 4",
      "Description 5": "Description 5",
      "Description 6": "Description 6",
      "Welcome to my Portfolio": "Welcome to my Portfolio",
      "Hi! I'm Andy": "Hi! I'm Andy",
      "Web Developer": "Web Developer",
      "Frontend Developer": "Frontend Developer",
      "Introduction": "Hi, I'm <strong>Andy DE COCK</strong>! I'm a third-year student at <strong>EPITECH Bordeaux</strong>. I am passionate about web development, especially the frontend part! I've even started freelancing as a frontend developer! Besides coding, I am interested in digital security, which will be my future specialty for the last two years of my studies.",
      "Let’s Connect": "Let’s Connect",
      "Header Img": "Header Img",
      "Principal Skills": "Principal Skills",
      "Skills Description": "Working on various projects has allowed me to gain proficiency in frontend tools and solid foundations in backend development.",
    }
  },
  fr: {
    translation: {
      "Home": "Accueil",
      "Skills": "Compétences",
      "Projects": "Projets",
      "Let’s Connect": "Entrer en contact",
      "Get In Touch": "Entrer en contact",
      "First Name": "Prénom",
      "Last Name": "Nom de famille",
      "Email Address": "Adresse électronique",
      "Phone No.": "Numéro de téléphone",
      "Message": "Message",
      "Send": "Envoyer",
      "Sending...": "Envoi en cours...",
      "Message sent successfully!": "Message envoyé avec succès !",
      "Something went wrong, please try again later.": "Quelque chose s'est mal passé, veuillez réessayer plus tard.",
      "With Link": "Avec lien",
      "Without Link": "Sans lien",
      "Overview": "Aperçu",
      "Project 1": "Clone Spotify",
      "Project 2": "Carne de santé en ligne",
      "Description 1": "Description 1",
      "Description 2": "Description 2",
      "Description 3": "Description 3",
      "Description 4": "Description 4",
      "Description 5": "Description 5",
      "Description 6": "Description 6",
      "Welcome to my Portfolio": "Bienvenue sur mon Portfolio",
      "Hi! I'm Andy": "Bonjour ! Je suis Andy",
      "Web Developer": "Développeur Web",
      "Frontend Developer": "Développeur Frontend",
      "Introduction": "Bonjour, je suis <strong>Andy DE COCK</strong> ! Je suis étudiant en troisième année à <strong>EPITECH Bordeaux</strong>. Je suis passionné par le développement web, en particulier la partie frontend ! J'ai même commencé à travailler en tant que développeur frontend en freelance ! En plus du code, je suis intéressé par la sécurité numérique, qui sera ma spécialité pour les deux dernières années de mes études.",
      "Let’s Connect": "Prenons contact",
      "Header Img": "Image d'en-tête",
      "Principal Skills": "Compétences Principales",
      "Skills Description": "Travailler sur divers projets m'a permis d'acquérir une maîtrise des outils frontend et de solides bases en développement backend.",
      "Spotify Clone in React": "Spotify Clone en React",
      "Online health record in Ruby on Rails": "Carnet de santé en ligne en Ruby on Rails",
      "Resources for Linked Projects": "Ressources des projets avec lien"
    }
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "en", // default language
    fallbackLng: "en",
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
