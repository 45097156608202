import { useTranslation } from 'react-i18next';
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/monspotify.png";
import projImg2 from "../assets/img/SuiviSante.png";
import projImg3 from "../assets/img/ruby.jpg";
import projImg4 from "../assets/img/node.png";
import projImg5 from "../assets/img/java.webp";
import projImg6 from "../assets/img/OpenBSD.png";
import projImg7 from "../assets/img/react.jpg";
import projImg8 from "../assets/img/react_native.avif";
import projImg9 from "../assets/img/THM.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import fakeCard from "../assets/img/fake_card.png";
import signup from "../assets/img/signup.png";
import SSmobile from "../assets/img/SS_mobile.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {
  const { t } = useTranslation();

  const projects = [
    {
      title: t("Project 1"),
      description: t("Spotify Clone in React"),
      imgUrl: projImg1,
      url: "https://www.monspotify.eu/",
      tech: "Ruby, Stimulus, PostgreSQL"
    },
    {
      title: t("Project 2"),
      description: t("Online health record in Ruby on Rails"),
      imgUrl: projImg2,
      url: "https://www.suivisante.org/",
      tech: "NodeJS, SQL"
    },
  ];

  const projectWithoutLink = [
    {
      title: "Car Rental",
      imgUrl: projImg3,
      tech: "Ruby, Stimulus, PostgreSQL"
    },
    {
      title: "Job Offers",
      imgUrl: projImg4,
      tech: "NodeJS, SQL"
    },
    {
      title: "2D Game",
      imgUrl: projImg5,
      tech: "Java"
    },
    {
      title: "DHCP Architecture",
      imgUrl: projImg6,
      tech: "OpenBSD, FreeBSD"
    },
    {
      title: "Chat Application",
      imgUrl: projImg7,
      tech: "Socket.io, React (NextJs / Typescript)"
    },
    {
      title: "Trello Frontend",
      imgUrl: projImg8,
      tech: "React Native + Trello API"
    },
    {
      title: "E-commerce",
      imgUrl: projImg7,
      tech: "Symfony/PHP + React"
    },
    {
      title: "Cybersecurity",
      imgUrl: projImg9,
      tech: "TryHackMe: Attacking various virtual machines from “easy” to “hard”"
    },
  ];

  const handleImageClick = (src) => {
    const imgWindow = window.open("", "_blank");
    imgWindow.document.write(`<img src="${src}" style="width: 100%;">`);
  };

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>{t('Projects')}</h2>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item style={{cursor: "pointer"}}>
                      <Nav.Link eventKey="first">{t('With Link')}</Nav.Link>
                    </Nav.Item>
                    <Nav.Item style={{cursor: "pointer"}}>
                      <Nav.Link eventKey="second">{t('Without Link')}</Nav.Link>
                    </Nav.Item>
                    <Nav.Item style={{cursor: "pointer"}}>
                      <Nav.Link eventKey="third">{t('Resources for Linked Projects')}</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          projects.map((project, index) => (
                            <ProjectCard
                              key={index}
                              {...project}
                            />
                          ))
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <Row>
                        {
                          projectWithoutLink.map((project, index) => (
                            <ProjectCard
                              key={index}
                              {...project}
                            />
                          ))
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <div className="ressources-section">
                        <Row>
                          <Col>
                            <div className="info-message">
                              <p>This is the first deployed version.</p>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="centered-img-container">
                            <h3>MonSpotify</h3>
                            <p>For the signup, enter a valid email for the email verification.</p>
                            <img src={signup} alt="Signup" onClick={() => handleImageClick(signup)} />
                          </Col>
                          <Col className="centered-img-container">
                            <p>You need premium access to listen to music. Use this fake credit card to test.</p>
                            <img src={fakeCard} alt="Fake Card" onClick={() => handleImageClick(fakeCard)} />
                          </Col>
                        </Row>
                        <Row>
                          <Col className="centered-img-container">
                            <h3>SuiviSante</h3>
                            <p>SuiviSante app is primarily for mobile devices.</p>
                            <img className="mobile-image" src={SSmobile} alt="SuiviSante" onClick={() => handleImageClick(SSmobile)} />
                          </Col>
                          <Col className="centered-img-container">
                            <p>You can use this account to test the SuiviSante app:</p>
                            <ul>
                              <li>Email: andy.test@test.com</li>
                              <li>Password: password</li>
                            </ul>
                            <img src={projImg2} alt="SuiviSante" onClick={() => handleImageClick(projImg2)} />
                          </Col>
                        </Row>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2} alt="background"/>
    </section>
  );
};
